import { takeEvery, all, put, call } from "redux-saga/effects";

// actionTypes
import * as actionTypes from "../redux/actionTypes";

// actions
import {
  currentResourceFetchSuccess,
  currentResourceFetchFailed,
  projectTargetTableDataFetchSuccess,
  projectTargetTableDataFetchFailed,
  vendorMasteTableDataFetchSuccess,
  vendorMasteTableDataFetchFailed,
  resourceMasteTableDataFetchSuccess,
  resourceMasteTableDataFetchFailed,
  archivedResourceTableDataFetchSuccess,
  archivedResourceTableDataFetchFailed,
  inActiveResourceTableDataFetchSuccess,
  inActiveResourceTableDataFetchFailed,
  userSettingsTableDataFetchSuccess,
  userSettingsTableDataFetchFailed,
  clientMasterTableDataFetchSuccess,
  clientMasterTableDataFetchFailed,
  clientAgreementTableDataFetchSuccess,
  clientAgreementTableDataFetchFailed,
  externalProductMasterTableDataFetchSuccess,
  externalProductMasterTableDataFetchFailed,
  purchaseOrderTableDataFetchSuccess,
  purchaseOrderTableDataFetchFailed,
  accountMasterTableDataFetchSuccess,
  accountMasterTableDataFetchFailed,
  requestClientTableDataFetchSuccess,
  requestClientTableDataFetchFailed,
  requestClientTableDataUpdateStart,
  requestClientTableDataUpdateSuccess,
  externalResourceTableDataFetchSuccess,
  externalResourceTableDataFetchFailed,
  TechnologyMasterTableDataFetchSuccess,
  TechnologyMasterTableDataFetchFailed,
  SettingTableDataFetchSuccess,
  SettingTableDataFetchFailed,
  accountCreateSuccess,
  accountUpdateSuccess,
  createVendorSuccess,
  updateVendorSuccess,
  clientAgreementCreateSuccess,
  clientAgreementUpdateSuccess,
  externalProductMasterCreateSuccess,
  externalProductMasterUpdateSuccess,
  TechnologyMasterTableDataPostSuccess,
  TechnologyMasterTableDataPutSuccess,
  userSettingsTableDataPostSuccess,
  userSettingsTableDataPutSuccess,
  purchaseOrderTableDataPostSuccess,
  purchaseOrderTableDataPutSuccess,
  resourceMasterCreateSuccess,
  resourceMasterUpdateSuccess,
  projectTargetCreateSuccess,
  projectTargetUpdateSuccess,
  clientMasterCreateSuccess,
  clientMasterUpdateSuccess,
  externalResourceCreateSuccess,
  externalResourceUpdateSuccess,
  SettingTableDataPutSuccess,
  resourceMasterUpdateFailed,
  resourceMasterCreateFailed,
  accountUpdateFailed,
  accountCreateFailed,
  requestClientTableDataUpdateFailed,
  compareReportProjectTargetTableDataFetchFailed,
  compareReportProjectTargetTableDataFetchSuccess,
  TechnologyMasterTableDataPostFailed,
  TechnologyMasterTableDataPutFailed,
  createVendorFailed,
  updateVendorFailed,
  externalProductMasterUpdateFailed,
  externalProductMasterCreateFailed,
  reasonMasterUpdateSuccess,
  reasonMasterUpdateFailed,
  reasonMasterTableDataFetchSuccess,
  reasonMasterTableDataFetchFailed,
  reasonMasterCreateFailed,
  reasonMasterCreateSuccess,
  FetchPermissionsSuccess,
} from "../redux/actions/masterActions";

// services
import {
  fetchCurrentResource,
  fetchProjectTarget,
  fetchVendorMaster,
  fetchResourceMaster,
  fetchArchivedResource,
  fetchInActiveResource,
  fetchUserSettingsMaster,
  fetchClientMaster,
  fetchClientAgreement,
  fetchExternalProductMaster,
  fetchPurchaseOrder,
  fetchAccount,
  fetchRequestClient,
  updateRequestClient,
  fetchTechnologyMaster,
  fetchexternalResourceMaster,
  fetchSetting,
  createAccount,
  updateAcount,
  createVendor,
  updateVendor,
  createClientAgreement,
  updateClientAgreement,
  createExProductMaster,
  updateExProductMaster,
  addTechnologyMaster,
  editTechnologyMaster,
  addUserSetting,
  editUserSetting,
  addPurchaseOrder,
  editPurchaseOrder,
  createResource,
  updateResource,
  createProjectTarget,
  updateProjectTarget,
  createClient,
  updateClient,
  createExternalResource,
  updateExternalResource,
  updateSetting,
  fetchCRProjectTarget,
  fetchReasonMaster,
  addReasonMaster,
  editReasonMaster,
  fetchPermissions,
} from "../api/services/masterService";

// Worker saga

// **************************** CURRENT RESOURCE START ****************************
function* loadCurrentResource(action) {
  try {
    const { data } = yield call(fetchCurrentResource, action.payload);
    yield put(
      currentResourceFetchSuccess({
        currentResource: data?.data?.data,
      })
    );
  } catch (error) {
    yield put(currentResourceFetchFailed(error.response));
  }
}

// **************************** COMPARE REPORT PROJECT TARGET START ****************************
function* loadCompareReportProjectTarget(action) {
  try {
    const { data } = yield call(fetchCRProjectTarget, action.payload);
    yield put(
      compareReportProjectTargetTableDataFetchSuccess({
        crProjectTarget: data?.project,
        count: data?.count,
      })
    );
  } catch (error) {
    yield put(compareReportProjectTargetTableDataFetchFailed(error.response));
  }
} // **************************** COMPARE REPORT PROJECT TARGET END ****************************

// ***************************** PROJECT TARGET MASTER START *****************************
function* loadProjectTarget(action) {
  try {
    const { data } = yield call(fetchProjectTarget, action.payload);
    yield put(
      projectTargetTableDataFetchSuccess({
        projectTarget: data?.data?.project,
        count: data?.count,
      })
    );
  } catch (error) {
    yield put(projectTargetTableDataFetchFailed(error.response));
  }
}

function* createProjectTargetMaster(action) {
  try {
    yield call(createProjectTarget, action.payload.values);
    const { data } = yield call(fetchProjectTarget, action.payload.fromTo);
    yield put(projectTargetCreateSuccess(data?.data?.projectTarget));
  } catch (error) {
    yield put(projectTargetTableDataFetchFailed(error.response));
  }
}

function* updateProjectTargetMaster(action) {
  try {
    const { resources, ...other } = action.payload;
    yield call(updateProjectTarget, other);
    yield put(projectTargetUpdateSuccess(action.payload));
  } catch (error) {
    yield put(projectTargetTableDataFetchFailed(error.response));
  }
}
// ***************************** PROJECT TARGET MASTER END *****************************

// ***************************** VENDOR MASTER START *****************************
function* loadVendorMaster(action) {
  try {
    const { data, count } = yield call(fetchVendorMaster, action.payload);
    yield put(
      vendorMasteTableDataFetchSuccess({ vendors: data?.vendors, count })
    );
  } catch (error) {
    yield put(vendorMasteTableDataFetchFailed(error.response));
  }
}
function* createVendorMaster(action) {
  try {
    yield call(createVendor, action.payload.values);
    const { data, count } = yield call(
      fetchVendorMaster,
      action.payload.fromTo
    );
    yield put(createVendorSuccess({ vendors: data?.vendors, count }));
  } catch (error) {
    yield put(createVendorFailed(error.response));
  }
}
function* updateVendorMaster(action) {
  try {
    yield call(updateVendor, action.payload);
    yield put(updateVendorSuccess(action.payload));
  } catch (error) {
    yield put(updateVendorFailed(error.response));
  }
}
// ***************************** VENDOR MASTER END *****************************

// ***************************** RESOURCE MASTER START *****************************
function* loadResourceMaster(action) {
  try {
    const { data, count } = yield call(fetchResourceMaster, action.payload);
    yield put(
      resourceMasteTableDataFetchSuccess({
        resourceMaster: data?.resources,
        count,
      })
    );
  } catch (error) {
    yield put(resourceMasteTableDataFetchFailed(error.response));
  }
}
function* createResourceMaster(action) {
  try {
    const res = yield call(createResource, action.payload);
    // yield put(resourceMasterCreateSuccess(vendor))
  } catch (error) {
    yield put(resourceMasterCreateFailed(error.response));
  }
}
function* updateResourceMaster(action) {
  try {
    yield call(updateResource, action.payload);
    yield put(resourceMasterUpdateSuccess(action.payload));
  } catch (error) {
    yield put(resourceMasterUpdateFailed(error.response));
  }
}
// ***************************** RESOURCE MASTER END *****************************

// ***************************** ARCHIVED RESOURCE START *****************************
function* loadArchivedResource(action) {
  try {
    const { data } = yield call(fetchArchivedResource, action.payload);
    const dataCount = data?.count;
    yield put(
      archivedResourceTableDataFetchSuccess({
        archivedResource: data?.data?.resources,
        count: dataCount,
      })
    );
  } catch (error) {
    yield put(archivedResourceTableDataFetchFailed(error.response));
  }
}
// ***************************** ARCHIVED RESOURCE END *****************************

// ***************************** INACTIVE RESOURCE START *****************************
function* loadInActiveResource(action) {
  try {
    const { data } = yield call(fetchInActiveResource, action.payload);
    const dataCount = data?.count;
    yield put(
      inActiveResourceTableDataFetchSuccess({
        inActiveResource: data?.data?.resources,
        count: dataCount,
      })
    );
  } catch (error) {
    yield put(inActiveResourceTableDataFetchFailed(error.response));
  }
}
// ***************************** INACTIVE RESOURCE END *****************************

// ***************************** USER SETTINGS START *****************************

function* loadUserSettings(action) {
  try {
    const data = yield call(fetchUserSettingsMaster, action.payload);
    // yield put(userSettingsTableDataFetchSuccess(data?.users));
    yield put(userSettingsTableDataFetchSuccess({
      users: data?.data?.users,
      count: data?.count,

    }));
  } catch (error) {
    yield put(userSettingsTableDataFetchFailed(error.response));
  }
}

function* createUserSettings(action) {
  try {
    const { data } = yield call(addUserSetting, action.payload);
    yield put(userSettingsTableDataPostSuccess(data));
  } catch (error) {
    yield put(userSettingsTableDataFetchFailed(error.response));
  }
}

function* updateUserSettings(action) {
  try {
    yield call(editUserSetting, action.payload);
    yield put(userSettingsTableDataPutSuccess(action.payload));
  } catch (error) {
    yield put(userSettingsTableDataFetchFailed(error.response));
  }
}
// ***************************** USER SETTINGS END *****************************

// ***************************** CLIENT MASTER START *****************************
function* loadClientMaster(action) {
  try {
    const { data } = yield call(fetchClientMaster, action.payload);
    yield put(
      clientMasterTableDataFetchSuccess({
        clients: data?.clients,
        count: data?.count,
      })
    );
  } catch (error) {
    yield put(clientMasterTableDataFetchFailed(error.response));
  }
}
function* createClientMaster(action) {
  try {
    const { client } = yield call(createClient, action.payload);
    // const {data} = yield call(fetchClientMaster);
    yield put(clientMasterCreateSuccess({ client: client }));
  } catch (error) {
    yield put(clientMasterTableDataFetchFailed(error.response));
  }
}
function* updateClientMaster(action) {
  try {
    yield call(updateClient, action.payload);
    yield put(clientMasterUpdateSuccess(action.payload));
  } catch (error) {
    yield put(clientMasterTableDataFetchFailed(error.response));
  }
}
// ***************************** CLIENT MASTER END *****************************

// ***************************** CLIENT AGREEMENT START *****************************
function* loadClientAgreement(action) {
  try {
    const { data, count } = yield call(fetchClientAgreement, action.payload);
    yield put(
      clientAgreementTableDataFetchSuccess({
        clientAgreement: data?.clientAgreements,
        count,
      })
    );
  } catch (error) {
    yield put(clientAgreementTableDataFetchFailed(error.response));
  }
}
function* createClientAgreementMaster(action) {
  try {
    yield call(createClientAgreement, action.payload.values);
    const { data, count } = yield call(
      fetchClientAgreement,
      action.payload.fromTo
    );
    yield put(
      clientAgreementCreateSuccess({
        clientAgreement: data?.clientAgreements,
        count,
      })
    );
  } catch (error) {
    yield put(clientAgreementTableDataFetchFailed(error.response));
  }
}
function* updateClientAgreementMaster(action) {
  try {
    yield call(updateClientAgreement, action.payload.values);
    const { data, count } = yield call(
      fetchClientAgreement,
      action.payload.fromTo
    );
    yield put(
      clientAgreementUpdateSuccess({
        clientAgreement: data?.clientAgreements,
        count,
      })
    );
  } catch (error) {
    yield put(clientAgreementTableDataFetchFailed(error.response));
  }
}
// ***************************** CLIENT AGREEMENT END *****************************

// ***************************** EXTERNAL PRODUCT MASTER START *****************************
function* loadExternalProductMaster(action) {
  try {
    const { data, count } = yield call(
      fetchExternalProductMaster,
      action.payload
    );
    yield put(
      externalProductMasterTableDataFetchSuccess({
        product: data?.product,
        count,
      })
    );
  } catch (error) {
    yield put(externalProductMasterTableDataFetchFailed(error.response));
  }
}
function* createExternalProductMaster(action) {
  try {
    yield call(createExProductMaster, action.payload.values);
    const { data, count } = yield call(
      fetchExternalProductMaster,
      action.payload.fromTo
    );
    yield put(
      externalProductMasterCreateSuccess({ product: data?.product, count })
    );
  } catch (error) {
    yield put(externalProductMasterCreateFailed(error.response));
  }
}
function* updateExternalProductMaster(action) {
  try {
    yield call(updateExProductMaster, action.payload);
    yield put(externalProductMasterUpdateSuccess(action.payload));
  } catch (error) {
    yield put(externalProductMasterUpdateFailed(error.response));
  }
}
// ***************************** EXTERNAL PRODUCT MASTER END *****************************

// ***************************** PURCHASE ORDER START *****************************
function* loadPurchaseOrder(action) {
  try {
    const { data, count } = yield call(fetchPurchaseOrder, action.payload);
    yield put(
      purchaseOrderTableDataFetchSuccess({ purchase: data?.purchase, count })
    );
  } catch (error) {
    yield put(purchaseOrderTableDataFetchFailed(error.response));
  }
}

function* createPurchaseOrder(action) {
  try {
    const { purchase } = yield call(addPurchaseOrder, action.payload.values);
    // const {data, count} = yield call(fetchPurchaseOrder, action.payload.fromTo);
    yield put(purchaseOrderTableDataPostSuccess({ purchase: purchase }));
  } catch (error) {
    yield put(purchaseOrderTableDataFetchFailed(error.response));
  }
}
function* updatePurchaseOrder(action) {
  try {
    const { clients, ...other } = action.payload.values;
    yield call(editPurchaseOrder, other);
    const { data } = yield call(fetchPurchaseOrder, action.payload.fromTo);
    yield put(purchaseOrderTableDataPutSuccess(data?.purchase));
  } catch (error) {
    yield put(purchaseOrderTableDataFetchFailed(error.response));
  }
}
// ***************************** PURCHASE ORDER START *****************************

// ***************************** ACCOUNT MASTER START *****************************

function* loadAccountMaster(action) {
  try {
    const { data } = yield call(fetchAccount, action.payload);
    yield put(
      accountMasterTableDataFetchSuccess({
        data: data?.data?.accounts,
        count: data?.count,
      })
    );
  } catch (error) {
    yield put(accountMasterTableDataFetchFailed(error.response));
  }
}
function* createAccountMaster(action) {
  try {
    const { account } = yield call(createAccount, action.payload.values);
    // const {data} = yield call(fetchAccount, action.payload.fromTo);
    yield put(accountCreateSuccess({ data: account }));
  } catch (error) {
    yield put(accountCreateFailed(error.response));
  }
}
function* updateAccountMaster(action) {
  try {
    const { data } = yield call(updateAcount, action.payload.values);
    // const {data} = yield call(fetchAccount);
    yield put(
      accountUpdateSuccess({ data: data?.data?.accounts, count: data?.count })
    );
  } catch (error) {
    yield put(accountUpdateFailed(error.response));
  }
}
// ***************************** ACCOUNT MASTER END *****************************

// ***************************** REQUEST CLIENT START END *****************************

function* loadRequestClient(action) {
  try {
    const { data, count } = yield call(fetchRequestClient, action.payload);
    yield put(requestClientTableDataFetchSuccess(data?.client_request, count));
  } catch (error) {
    yield put(requestClientTableDataFetchFailed(error.response));
  }
}

function* updateReqClient(action) {
  try {
    const res = yield call(updateRequestClient, action.payload);
    yield put(requestClientTableDataUpdateSuccess(res));
  } catch (error) {
    yield put(requestClientTableDataUpdateFailed(error.response));
  }
}

// ***************************** REQUEST CLIENT  END *****************************

// ***************************** EXTERNAL RESOURCE MASTER START *****************************

function* loadExternalResourceMaster() {
  try {
    const { data } = yield call(fetchexternalResourceMaster);
    yield put(externalResourceTableDataFetchSuccess(data?.externalResource));
  } catch (error) {
    yield put(externalResourceTableDataFetchFailed(error.response));
  }
}
function* createExternalResourceMaster(action) {
  try {
    const res = yield call(createExternalResource, action.payload);
    // yield put(externalResourceCreateSuccess(res))
  } catch (error) {
    yield put(externalResourceTableDataFetchFailed(error.response));
  }
}
function* updateExternalResourceMaster(action) {
  try {
    yield call(updateExternalResource, action.payload);
    yield put(externalResourceUpdateSuccess(action.payload));
  } catch (error) {
    yield put(externalResourceTableDataFetchFailed(error.response));
  }
}
// *****************************EXTERNAL RESOURCE MASTER END *****************************

// *****************************TECHNOLOGY MASTER START *****************************
function* loadTechnologyMaster(action) {
  try {
    const { data } = yield call(fetchTechnologyMaster, action.payload);
    yield put(
      TechnologyMasterTableDataFetchSuccess({
        technologies: data?.technologies,
        count: data?.count,
      })
    );
  } catch (error) {
    yield put(TechnologyMasterTableDataFetchFailed(error.response));
  }
}

function* createTechnologyMaster(action) {
  try {
    const { technology } = yield call(addTechnologyMaster, action.payload);
    yield put(TechnologyMasterTableDataPostSuccess(technology));
  } catch (error) {
    yield put(TechnologyMasterTableDataPostFailed(error.response));
  }
}

function* updateTechnologyMaster(action) {
  try {
    const res = yield call(editTechnologyMaster, action.payload);
    yield put(TechnologyMasterTableDataPutSuccess(action.payload));
  } catch (error) {
    yield put(TechnologyMasterTableDataPutFailed(error.response));
  }
}
// *****************************TECHNOLOGY MASTER END *****************************

// ***************************** REASON MASTER START *****************************
function* loadReasonMaster(action) {
  try {
    const { data, count } = yield call(fetchReasonMaster, action.payload);
    yield put(
      reasonMasterTableDataFetchSuccess({ reasonData: data?.reasonData, count })
    );
  } catch (error) {
    yield put(reasonMasterTableDataFetchFailed(error.response));
  }
}
function* createReasonMaster(action) {
  try {
    const { reason } = yield call(addReasonMaster, action.payload);
    yield put(reasonMasterCreateSuccess(reason));
  } catch (error) {
    yield put(reasonMasterCreateFailed(error.response));
  }
}
function* updateReasonMaster(action) {
  try {
    yield call(editReasonMaster, action.payload);
    yield put(reasonMasterUpdateSuccess(action.payload));
  } catch (error) {
    yield put(reasonMasterUpdateFailed(error.response));
  }
}
// ***************************** REASON MASTER END *****************************

function* loadSetting() {
  try {
    const { data } = yield call(fetchSetting);
    yield put(SettingTableDataFetchSuccess(data));
  } catch (error) {
    yield put(SettingTableDataFetchFailed(error.response));
  }
}

function* editSetting(action) {
  try {
    const res = yield call(updateSetting, action.payload);
    yield put(SettingTableDataPutSuccess(action.payload));
  } catch (error) {
    yield put(SettingTableDataFetchFailed(error.response));
  }
}

function* loadPermissions(action) {
  const res = yield call(fetchPermissions, action.payload);
  yield put(FetchPermissionsSuccess(res));
}

// watcher saga

function* watchCurrentResource() {
  yield takeEvery(
    actionTypes.CURRENT_RESOURCE_FETCH_START,
    loadCurrentResource
  );
}

function* watchCRProjectTarget() {
  yield takeEvery(
    actionTypes.CR_PROJECT_TARGET_DATA_FETCH_START,
    loadCompareReportProjectTarget
  );
}

function* watchProjectTarget() {
  yield takeEvery(
    actionTypes.PROJECT_TABLE_DATA_FETCH_START,
    loadProjectTarget
  );
  yield takeEvery(
    actionTypes.PROJECT_TARGET_CREATE_START,
    createProjectTargetMaster
  );
  yield takeEvery(
    actionTypes.PROJECT_TARGET_UPDATE_START,
    updateProjectTargetMaster
  );
}

function* watchVendorMaster() {
  yield takeEvery(actionTypes.VENDOR_TABLE_DATA_FETCH_START, loadVendorMaster);
  yield takeEvery(actionTypes.VENDOR_CREATE_START, createVendorMaster);
  yield takeEvery(actionTypes.VENDOR_UPDATE_START, updateVendorMaster);
}
function* watchResourceMaster() {
  yield takeEvery(
    actionTypes.RESOURCE_TABLE_DATA_FETCH_START,
    loadResourceMaster
  );
  yield takeEvery(
    actionTypes.RESOURCE_MASTER_CREATE_START,
    createResourceMaster
  );
  yield takeEvery(
    actionTypes.RESOURCE_MASTER_UPDATE_START,
    updateResourceMaster
  );
}
function* watchArchivedResouce() {
  yield takeEvery(
    actionTypes.ARCHIVED_RESOURCE_TABLE_DATA_FETCH_START,
    loadArchivedResource
  );
}
function* watchInActiveResouce() {
  yield takeEvery(
    actionTypes.INACTIVE_RESOURCE_TABLE_DATA_FETCH_START,
    loadInActiveResource
  );
}
function* watchUserSettings() {
  yield takeEvery(
    actionTypes.USER_SETTINGS_TABLE_DATA_FETCH_START,
    loadUserSettings
  );
  yield takeEvery(
    actionTypes.USER_SETTINGS_TABLE_DATA_POST_START,
    createUserSettings
  );
  yield takeEvery(
    actionTypes.USER_SETTINGS_TABLE_DATA_PUT_START,
    updateUserSettings
  );
}
function* watchClientMaster() {
  yield takeEvery(
    actionTypes.CLIENT_MASTER_TABLE_DATA_FETCH_START,
    loadClientMaster
  );
  yield takeEvery(actionTypes.CLIENT_MASTER_CREATE_START, createClientMaster);
  yield takeEvery(actionTypes.CLIENT_MASTER_UPDATE_START, updateClientMaster);
}
function* watchClientAgreement() {
  yield takeEvery(
    actionTypes.CLIENT_AGREEMENT_TABLE_DATA_FETCH_START,
    loadClientAgreement
  );
  yield takeEvery(
    actionTypes.CLIENT_AGREEMENT_CREATE_START,
    createClientAgreementMaster
  );
  yield takeEvery(
    actionTypes.CLIENT_AGREEMENT_UPDATE_START,
    updateClientAgreementMaster
  );
}
function* watchExternalProductMaster() {
  yield takeEvery(
    actionTypes.EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_START,
    loadExternalProductMaster
  );
  yield takeEvery(
    actionTypes.EXTERNAL_PRODUCT_MASTER_CREATE_START,
    createExternalProductMaster
  );
  yield takeEvery(
    actionTypes.EXTERNAL_PRODUCT_MASTER_UPDATE_START,
    updateExternalProductMaster
  );
}
function* watchPurchaseOrder() {
  yield takeEvery(
    actionTypes.PURCHASE_ORDER_TABLE_DATA_FETCH_START,
    loadPurchaseOrder
  );
  yield takeEvery(
    actionTypes.PURCHASE_ORDER_TABLE_DATA_POST_START,
    createPurchaseOrder
  );
  yield takeEvery(
    actionTypes.PURCHASE_ORDER_TABLE_DATA_PUT_START,
    updatePurchaseOrder
  );
}
function* watchAccountMaster() {
  yield takeEvery(
    actionTypes.ACCOUNT_TABLE_DATA_FETCH_START,
    loadAccountMaster
  );
  yield takeEvery(actionTypes.ACCOUNT_CREATE_START, createAccountMaster);
  yield takeEvery(actionTypes.ACCOUNT_UPDATE_START, updateAccountMaster);
}
function* watchRequestClient() {
  yield takeEvery(
    actionTypes.REQUEST_CLIENT_TABLE_DATA_FETCH_START,
    loadRequestClient
  );
  yield takeEvery(
    actionTypes.REQUEST_CLIENT_TABLE_DATA_UPDATE_START,
    updateReqClient
  );
}
function* watchTechnologyMaster() {
  yield takeEvery(
    actionTypes.TECHNOLOGY_TABLE_DATA_FETCH_START,
    loadTechnologyMaster
  );
  yield takeEvery(
    actionTypes.TECHNOLOGY_DATA_POST_START,
    createTechnologyMaster
  );
  yield takeEvery(
    actionTypes.TECHNOLOGY_DATA_PUT_START,
    updateTechnologyMaster
  );
}
function* watchReasonMaster() {
  yield takeEvery(
    actionTypes.REASON_MASTER_TABLE_DATA_FETCH_START,
    loadReasonMaster
  );
  yield takeEvery(actionTypes.REASON_MASTER_CREATE_START, createReasonMaster);
  yield takeEvery(actionTypes.REASON_MASTER_UPDATE_START, updateReasonMaster);
}
function* watchExternalResourceMaster() {
  yield takeEvery(
    actionTypes.EXTERNALRESOURCE_TABLE_DATA_FETCH_START,
    loadExternalResourceMaster
  );
  yield takeEvery(
    actionTypes.EXTERNALRESOURCE_CREATE_START,
    createExternalResourceMaster
  );
  yield takeEvery(
    actionTypes.EXTERNALRESOURCE_UPDATE_START,
    updateExternalResourceMaster
  );
}

function* watchSetting() {
  yield takeEvery(actionTypes.SETTING_TABLE_DATA_FETCH_START, loadSetting);
  yield takeEvery(actionTypes.SETTING_TABLE_DATA_PUT_START, editSetting);
}

function* watchPermissions() {
  yield takeEvery(actionTypes.FETCH_PERMISSIONS, loadPermissions)
}

export function* mastersSaga() {
  yield all([
    watchCurrentResource(),
    watchCRProjectTarget(),
    watchProjectTarget(),
    watchVendorMaster(),
    watchResourceMaster(),
    watchArchivedResouce(),
    watchInActiveResouce(),
    watchUserSettings(),
    watchClientMaster(),
    watchClientAgreement(),
    watchExternalProductMaster(),
    watchPurchaseOrder(),
    watchAccountMaster(),
    watchRequestClient(),
    watchExternalResourceMaster(),
    watchTechnologyMaster(),
    watchReasonMaster(),
    watchSetting(),
    watchPermissions()
  ]);
}
