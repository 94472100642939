import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    Setting: [],
    isError: false,
    isUpdating: false,
    error: {}
}

export const settingsReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.SETTING_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                isUpdating: false,
                error: {}
            }

        case actionTypes.SETTING_TABLE_DATA_FETCH_SUCCESS:
            return {
                ...state,
                isDataFetch: false,
                Setting: [...action.payload],
                isError: false,
                isUpdating: false,
                error: {}
            }

        case actionTypes.SETTING_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                Setting: [],
                error: { ...action.payload }
            }

        case actionTypes.SETTING_TABLE_DATA_PUT_START:
            return {
                ...state,
                isUpdating: true,
                isDataFetch: true,

            }

        case actionTypes.SETTING_TABLE_DATA_PUT_SUCCESS:
            const settingData = [...state.Setting]
            // const findIndexS = settingData.findIndex(d => d.id === action.payload.id)
            // settingData[findIndexS] = { ...action.payload }
            return {
                ...state,
                Setting: [...settingData],
                isError: false,
                isUpdating: false,
                isDataFetch: false,
                error: {}
            }
        default:
            return state
    }
}
