import {  INTERNAL_INVOICE_HISTORY, INVOICE_MASTER_INVOICE_DETAILS } from "../apiEndpoints";
import { privateRequest } from "../axiosConfig/privateRequest";

export const fetchInvoiceStatus = (payload) => {
    // console.log('searchValue', searchValue);
    return privateRequest.get(`${INVOICE_MASTER_INVOICE_DETAILS}${payload}`)
}

export const fetchInvoiceHistory = (payload) => {
    return privateRequest.get(`${INTERNAL_INVOICE_HISTORY}${payload}`)
}

export const fetchExternalInvoiceStatus = (payload) => {
    return privateRequest.get(`${INVOICE_MASTER_INVOICE_DETAILS}${payload}`)
}

export const fetchExternalInvoiceHistory = (payload) => {
    return privateRequest.get(`${INTERNAL_INVOICE_HISTORY}${payload}`)
}
