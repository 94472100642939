import * as actionTypes from "../actionTypes";

const inititalState = {
  isDataFetch: false,
  internalReport: [],
  externalReport: [],
  totalCount: 0,
  isError: false,
};

export const reportReducer = (state = inititalState, action) => {
  switch (action.type) {
    case actionTypes.EXTERNAL_INTERVIEW_REPORT_START:
    case actionTypes.INTERNAL_INTERVIEW_REPORT_START:
      return {
        ...state,
        isDataFetch: true,
        isError: false,
      };

    case actionTypes.EXTERNAL_INTERVIEW_REPORT_FAILED:

    case actionTypes.INTERNAL_INTERVIEW_REPORT_FAILED:
      return {
        ...state,
        isError: true,
        isDataFetch: false,
        internalReport: [],
        externalReport: [],
      };
    case actionTypes.EXTERNAL_INTERVIEW_REPORT_SUCCESS:
      return {
        ...state,
        isDataFetch: false,
        externalReport: [...action.payload],
        isError: false,
      };
    case actionTypes.INTERNAL_INTERVIEW_REPORT_SUCCESS:
      return {
        ...state,
        isDataFetch: false,
        internalReport: [...action.payload.internalReport],
        totalCount: action.payload.count,
        isError: false,
      };
    default:
      return state;
  }
};
