import { showErrorNotification, showSuccessNotification } from "../../utility";
import * as actionTypes from "../actionTypes";

const configToast = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const forgetActionStart = (email) => {
  return {
    type: actionTypes.FORGET_START,
    payload: email,
  };
};

export const forgetActionSuccess = (user) => {
  showSuccessNotification("Password reset link sent you on your Email!", configToast);
  return {
    type: actionTypes.FORGET_SUCCESS,
    payload: user,
  };
};

export const forgetActionFaild = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.FORGET_FAILD,
    payload: error,
  };
};