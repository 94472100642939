import * as actionTypes from '../actionTypes';
export const forgetReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FORGET_START:
            return {
                ...state,
                loading: true,
                isLoggedIn:true
            }
        case actionTypes.FORGET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                forgotpass: action.payload
            }
        case actionTypes.FORGET_FAILD:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}