import * as actionTypes from '../../actionTypes';

const initialState = {
    permissions: []
}

export const currentPermissionsReducer = (state = initialState, action) => {
    switch (action.type){
        case actionTypes.FETCH_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: action.payload.data.data
            }
        case actionTypes.CLEAR_PERMISSIONS:
            return {
                ...state,
                ...initialState
            }
        default: 
            return state
    }
}