import axios from "axios";
import { privateRequest } from "../axiosConfig/privateRequest";
import { HOME, CURRENT_RESOURCE, PERMISSION } from "../apiEndpoints";

export const fetchCurrentResource = (val) => {
  let url =
    HOME +
    CURRENT_RESOURCE +
    `?location=${val.location}&Technology=${val.technology}&exp=${val.exp}`;
  return privateRequest.get(url);
};

// **************************** COMPARE REPORT PROJECT TARGET START ****************************
export const fetchCRProjectTarget = (pagination) => {
  console.log("pagination", pagination);
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest
      .get(
        `/report/projectTargets?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
      )
      .then((res) => res.data);
  } else {
    return privateRequest
      .get(
        `/report/projectTargets?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
      )
      .then((res) => res.data);
  }
};
// **************************** COMPARE REPORT PROJECT TARGET END ****************************

// ***************************** PROJECT TARGET MASTER START *****************************
export const fetchProjectTarget = (pagination, searchValue = "") => {
  console.log("pagination", pagination);
  console.log("searchValue", searchValue);
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest.get(
      `/project-target?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
    );
    // .then(res => res.data)
  } else {
    return privateRequest.get(
      `/project-target?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
    );
  }
};
// **************************** PROJECT TARGET MASTER END ****************************

export const fetchVendorMaster = (pagination) => {
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest
      .get(
        `/vendor?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
      )
      .then((res) => res.data);
  } else {
    return privateRequest
      .get(
        `/vendor?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
      )
      .then((res) => res.data);
  }
  // return privateRequest.get(`/vendor?from=${pagination.from}&to=${pagination.to}`).then(res => res.data)
};

export const fetchResourceMaster = (pagination) => {
  console.log("searchApi", pagination.search);
  // return privateRequest.get(`/resource?search=${pagination.search}&from=${pagination.from}&to=${pagination.to}`).then(res => res.data)
  if (pagination.order != "" && pagination.head != "") {
    return privateRequest
      .get(
        `/resource?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
      )
      .then((res) => res.data);
  } else if (
    pagination.startDate != undefined &&
    pagination.endDate != undefined
  ) {
    return privateRequest
      .get(
        `/resource?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&startDate=${pagination.startDate}&endDate=${pagination.endDate}`
      )
      .then((res) => res.data);
  } else {

    return privateRequest
      .get(`/resource?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`)

      .then((res) => res.data)

  }
};

export const fetchArchivedResource = (pagination, search = "") => {
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest.get(
      `/resource/getAllArchive?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
    );
    // .then(res => res.data)
  } else {
    return privateRequest.get(
      `/resource/getAllArchive?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
    );
  }
};

export const fetchInActiveResource = (pagination, search = "") => {
  console.log("searchApi", pagination);
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest.get(
      `/resource/getAllInactive?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
    ); // .then(res => res.data)
  } else {
    return privateRequest.get(
      `/resource/getAllInactive?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
    );
  }
};

export const fetchUserSettingsMaster = (pagination, searchValue = "") => {
  console.log(pagination)
  if (pagination.head !== '' && pagination.order !== '' && pagination.head !== undefined && pagination.order !== undefined) {
    console.log('API called with sorting');
    return privateRequest
      .get(`/user?search=${searchValue}&from=${pagination.from || ""}&to=${pagination.to || ""}&sort=${pagination.head}&orderby=${pagination.order}`)
      .then((res) => res.data);
  }
  else {
    console.log('API called without sorting');
    return privateRequest
      .get(`/user?search=${searchValue}&from=${pagination.from || ""}&to=${pagination.to || ""}`)
      .then((res) => res.data);
  }

};

export const fetchArchiveMaster = () => {
  return privateRequest.get(`getAllArchive`);
};

export const fetchClientMaster = (pagination) => {
  console.log("pagination :>> ", pagination);
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest
      .get(`/client?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`)
      .then((res) => res.data);


  } else {
    return privateRequest
      .get(
        `/client?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
      )
      .then((res) => res.data);
  }
};

export const fetchClientAgreement = (pagination, searchValue = "") => {
  console.log(
    "paginationhhhhh",
    pagination.searchValue,
    pagination.from,
    pagination.to
  );
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest
      .get(
        `/client-agreement?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
      )
      .then((res) => res.data);
  } else {
    return privateRequest
      .get(
        `/client-agreement?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
      )
      .then((res) => res.data);
  }
};

export const fetchExternalProductMaster = (pagination) => {
  const tem = {
    from: pagination?.from || null,
    to: pagination?.to || null,
    sort: pagination?.head || null,
    orderby: pagination?.order || null,
    search: pagination?.searchValue || null,
  };
  console.log("tem", tem);
  let payload = Object.keys(tem).map((e) => {
    if (tem[e]) {
      return e + "=" + tem[e];
    }
  });
  payload = payload.filter((el) => {
    return el !== undefined;
  });
  payload = payload.join("&");
  console.log("payload", payload);
  console.log("pagination", pagination);
  return privateRequest
    .get(`/external-products?${payload}`)
    .then((res) => res.data);

  // return privateRequest.get(`/external-products?search=${searchValue}&from=${pagination.from}&to=${pagination.to}`).then(res => res.data)
};

export const fetchPurchaseOrder = (pagination) => {
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest
      .get(
        `/purchase?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
      )
      .then((res) => res.data);
  } else {
    return privateRequest
      .get(
        `/purchase?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
      )
      .then((res) => res.data);
  }
};

export const addPurchaseOrder = (data) => {
  return privateRequest.post("/purchase", data).then((res) => res);
};

export const editPurchaseOrder = (data) => {
  return privateRequest.put(`/purchase/${data.id}`, data).then((res) => res);
};

export const fetchAccount = (pagination) => {
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest
      .get(
        `/account?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
      )
      .then((res) => res);
  } else {
    return privateRequest
      .get(
        `/account?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
      )
      .then((res) => res);
  }
  // return privateRequest
  //   .get(`/account?search=${searchValue}&from=${from}&to=${to}`)
  //   .then((res) => res);
};

export const fetchRequestClient = (pagination) => {
  console.log("searchValue1223", pagination);
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest
      .get(
        `/client-request?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
      )
      .then((res) => res.data);
  } else {
    return privateRequest
      .get(
        `/client-request?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
      )
      .then((res) => res.data);
  }
  // return privateRequest.get(`/client-request?search=${searchValue}`).then(res => res.data)
};

//setting
export const fetchSetting = () => {
  return privateRequest.get("/setting").then((res) => res.data);
};

export const updateSetting = (data) => {
  return privateRequest.put(`/setting/${data.id}`, data).then((res) => res);
};

export const fetchexternalResourceMaster = () => {
  return privateRequest.get("/external-resource").then((res) => res.data);
};
export const fetchTechnologyMaster = (pagination) => {
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest
      .get(
        `/technology?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
      )
      .then((res) => res.data);
  } else {
    return privateRequest
      .get(
        `/technology?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
      )
      .then((res) => res.data);
  }
};
export const fetchReasonMaster = (pagination) => {
  console.log(pagination)
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest
      .get(
        `/reason?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`
      )
      .then((res) => res.data);
  } else {
    return privateRequest
      .get(
        `/reason?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`
      )
      .then((res) => res.data);
  }
};
// Post (Create)
export const createAccount = (data) => {
  return privateRequest.post("/account", data).then((res) => res);
  // return {id: Date.now(), ...data}
};
export const createVendor = (data) => {
  return privateRequest.post("/vendor", data).then((res) => res?.data?.data);
};
export const createClientAgreement = (data) => {
  return privateRequest
    .post("/client-agreement", data)
    .then((res) => res?.data?.data);
};
export const createExProductMaster = (data) => {
  return privateRequest
    .post("/external-products", data)
    .then((res) => res?.data?.data);
};
export const addTechnologyMaster = (data) => {
  return privateRequest
    .post("/technology", data)
    .then((res) => res?.data?.data);
};
export const addReasonMaster = (data) => {
  return privateRequest.post("/reason", data).then((res) => res?.data?.data);
};
export const createResource = (data) => {
  return privateRequest.post("/resource", data).then((res) => res?.data?.data);
};
export const createProjectTarget = (data) => {
  return privateRequest
    .post("/project-target", data)
    .then((res) => res?.data?.data);
};
export const createClient = (data) => {
  console.log("add client data: ", data);
  return privateRequest.post("/client", data).then((res) => res?.data?.data);
};
export const addUserSetting = (data) => {
  return privateRequest.post("/user", data).then((res) => res);
};
export const createExternalResource = (data) => {
  return privateRequest
    .post("/external-resource", data)
    .then((res) => res?.data?.data);
};

// Put (update)
export const updateAcount = (data) => {
  console.log("update account: ", data);
  return privateRequest.put(`/account/${data.id}`, data).then((res) => res);
  // return data
};

export const updateVendor = (data) => {
  return privateRequest.put(`/vendor/${data.id}`, data).then((res) => res);
};
export const updateClientAgreement = (data) => {
  return privateRequest
    .put(`/client-agreement/${data.id}`, data)
    .then((res) => res);
};
export const updateExProductMaster = (data) => {
  const { id, created_at, updated_at, ...other } = data;
  return privateRequest
    .put(`/external-products/${id}`, other)
    .then((res) => res);
};
export const editTechnologyMaster = (data) => {
  console.log("update tech data: ", data);
  return privateRequest.put(`/technology/${data.id}`, data).then((res) => res);
};
export const editReasonMaster = (data) => {
  const { id, created_at, updated_at, ...other } = data;
  return privateRequest.put(`/reason/${id}`, other).then((res) => res);
};
export const updateResource = (data) => {
  return privateRequest.put(`/resource/${data.id}`, data).then((res) => res);
};
export const updateProjectTarget = (data) => {
  return privateRequest
    .put(`/project-target/${data.id}`, data)

    .then((res) => res);
};
export const updateClient = (data) => {
  return privateRequest.put(`/client/${data.id}`, data).then((res) => res);
};
export const editUserSetting = (data) => {
  return privateRequest.put(`/user/${data.id}`, data).then((res) => res);
};
export const updateExternalResource = (data) => {
  return privateRequest
    .put(`/external-resource/${data.id}`, data)
    .then((res) => res);
};

export const updateRequestClient = (data) => {
  // console.log("id",id);
  return privateRequest
    .put(`/client-request/accept-existing/${data.id}`, data)
    .then((res) => res.data);
};

export const fetchPermissions = (id) => {
  return privateRequest.get(`${PERMISSION}/${id}`).then(res => res.data)
}
