import * as actionTypes from "../actionTypes";

import { showSuccessNotification, showErrorNotification } from "../../utility";

const configToast = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const currentResourceFetchStart = (location, technology, exp) => {
  return {
    type: actionTypes.CURRENT_RESOURCE_FETCH_START,
    payload: { location, technology, exp },
  };
};

export const currentResourceFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Succesfully', configToast)
  return {
    type: actionTypes.CURRENT_RESOURCE_FETCH_SUCCESS,
    payload: data,
  };
};

export const currentResourceFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.CURRENT_RESOURCE_FETCH_FAILED,
    payload: error,
  };
};

// **************************** COMPARE REPORT PROJECT TARGET START ****************************
export const compareReportProjectTargetTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  return {
    type: actionTypes.CR_PROJECT_TARGET_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const compareReportProjectTargetTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Succesfully', configToast)
  return {
    type: actionTypes.CR_PROJECT_TARGET_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const compareReportProjectTargetTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.CR_PROJECT_TARGET_DATA_FETCH_FAILED,
    payload: error,
  };
};
// **************************** COMPARE REPORT PROJECT TARGET END ****************************

// **************************** PROJECT TARGET START ****************************
export const projectTargetTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  return {
    type: actionTypes.PROJECT_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const projectTargetTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Succesfully', configToast)
  return {
    type: actionTypes.PROJECT_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const projectTargetTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.PROJECT_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// ADD
export const projectTargetCreateStart = (data) => {
  return {
    type: actionTypes.PROJECT_TARGET_CREATE_START,
    payload: data,
  };
};

export const projectTargetCreateSuccess = (data) => {
  showSuccessNotification("Project Target Added Succesfully!", configToast);
  return {
    type: actionTypes.PROJECT_TARGET_CREATE_SUCCESS,
    payload: data,
  };
};

export const projectTargetCreateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.PROJECT_TARGET_CREATE_FAILED,
    payload: error,
  };
};

// UPDATE
export const projectTargetUpdateStart = (data) => {
  return {
    type: actionTypes.PROJECT_TARGET_UPDATE_START,
    payload: data,
  };
};

export const projectTargetUpdateSuccess = (data) => {
  showSuccessNotification("Data Updated Successfully!", configToast);
  return {
    type: actionTypes.PROJECT_TARGET_UPDATE_SUCCESS,
    payload: data,
  };
};

export const projectTargetUpdateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.PROJECT_TARGET_UPDATE_FAILED,
    payload: error,
  };
};
// **************************** PROJECT TARGET END ****************************

// **************************** VENDOR MASTER START ****************************
export const vendorMasteTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {

  return {
    type: actionTypes.VENDOR_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const vendorMasteTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data load Succefully!', configToast)
  return {
    type: actionTypes.VENDOR_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const vendorMasteTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  // toast.error(error?.data?.message, configToast)
  return {
    type: actionTypes.VENDOR_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// ADD VENDOR
export const createVendorStart = (data) => {
  return {
    type: actionTypes.VENDOR_CREATE_START,
    payload: data,
  };
};
export const createVendorSuccess = (data) => {
  showSuccessNotification("Partner Added Successfully!", configToast);
  return {
    type: actionTypes.VENDOR_CREATE_SUCCESS,
    payload: data,
  };
};
export const createVendorFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.VENDOR_CREATE_FAILED,
    payload: error,
  };
};
// UPDATE VENDOR
export const updateVendorStart = (data) => {
  return {
    type: actionTypes.VENDOR_UPDATE_START,
    payload: data,
  };
};
export const updateVendorSuccess = (data) => {
  showSuccessNotification("Partner Updated Successfully!", configToast);
  return {
    type: actionTypes.VENDOR_UPDATE_SUCCESS,
    payload: data,
  };
};
export const updateVendorFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.VENDOR_UPDATE_FAILED,
    payload: error,
  };
};
// **************************** VENDOR MASTER END ****************************

// **************************** RESOURCE MASTER START ****************************
export const resourceMasteTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order,
  startDate,
  endDate
) => {
  return {
    type: actionTypes.RESOURCE_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order, startDate, endDate },
  };
};

export const resourceMasteTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Succesfully!', configToast)
  return {
    type: actionTypes.RESOURCE_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const resourceMasteTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.RESOURCE_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// ADD
export const resourceMasterCreateStart = (data) => {
  return {
    type: actionTypes.RESOURCE_MASTER_CREATE_START,
    payload: data,
  };
};
export const resourceMasterCreateSuccess = (data) => {
  showSuccessNotification("Resource Added Successfully", configToast);
  return {
    type: actionTypes.RESOURCE_MASTER_CREATE_SUCCESS,
    payload: data,
  };
};
export const resourceMasterCreateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.RESOURCE_MASTER_CREATE_FAILED,
    payload: error,
  };
};

// UPDATE
export const resourceMasterUpdateStart = (data) => {
  return {
    type: actionTypes.RESOURCE_MASTER_UPDATE_START,
    payload: data,
  };
};
export const resourceMasterUpdateSuccess = (data) => {
  showErrorNotification("Resource Updated Successfully", configToast);
  return {
    type: actionTypes.RESOURCE_MASTER_UPDATE_SUCCESS,
    payload: data,
  };
};
export const resourceMasterUpdateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.RESOURCE_MASTER_UPDATE_FAILED,
    payload: error,
  };
};
// **************************** RESOURCE MASTER END ****************************

// **************************** ARCHIVED RESOURCE START ****************************
export const archivedResourceTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  return {
    type: actionTypes.ARCHIVED_RESOURCE_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const archivedResourceTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Succesfully!', configToast)
  return {
    type: actionTypes.ARCHIVED_RESOURCE_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const archivedResourceTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.ARCHIVED_RESOURCE_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// **************************** ARCHIVED RESOURCE END ****************************

// **************************** INACTIVE RESOURCE START ****************************
export const inActiveResourceTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  console.log(head, order)
  return {
    type: actionTypes.INACTIVE_RESOURCE_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const inActiveResourceTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Succesfully!', configToast)
  return {
    type: actionTypes.INACTIVE_RESOURCE_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const inActiveResourceTableDataFetchFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.INACTIVE_RESOURCE_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// **************************** INACTIVE RESOURCE END ****************************

// **************************** USER SETTINGS START ****************************
// export const userSettingsTableDataFetchStart = (searchValue) => {
//   return {
//     type: actionTypes.USER_SETTINGS_TABLE_DATA_FETCH_START,
//     payload: { searchValue },
//   };
// };

export const userSettingsTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  console.log(head, order)
  return {
    type: actionTypes.USER_SETTINGS_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};
export const userSettingsTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Successfully!', configToast)
  return {
    type: actionTypes.USER_SETTINGS_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const userSettingsTableDataFetchFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.USER_SETTINGS_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

export const userSettingsTableDataPostStart = (data) => {
  return {
    type: actionTypes.USER_SETTINGS_TABLE_DATA_POST_START,
    payload: data,
  };
};

export const userSettingsTableDataPostSuccess = (data) => {
  showSuccessNotification("Data Added Successfully!", configToast);
  return {
    type: actionTypes.USER_SETTINGS_TABLE_DATA_POST_SUCCESS,
    payload: data,
  };
};

export const userSettingsTableDataPutStart = (data) => {
  return {
    type: actionTypes.USER_SETTINGS_TABLE_DATA_PUT_START,
    payload: data,
  };
};

export const userSettingsTableDataPutSuccess = (data) => {
  showSuccessNotification("Data Updated Successfully!", configToast);
  return {
    type: actionTypes.USER_SETTINGS_TABLE_DATA_PUT_SUCCESS,
    payload: data,
  };
};

// **************************** USER SETTINGS END ****************************

// **************************** CLIENT MASTER START ****************************
export const clientMasterTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {

  return {
    type: actionTypes.CLIENT_MASTER_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const clientMasterTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Successfully', configToast)
  return {
    type: actionTypes.CLIENT_MASTER_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const clientMasterTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.CLIENT_MASTER_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// ADD
export const clientMasterCreateStart = (data) => {
  return {
    type: actionTypes.CLIENT_MASTER_CREATE_START,
    payload: data,
  };
};

export const clientMasterCreateSuccess = (data) => {
  showSuccessNotification("Client Added Successfully!", configToast);
  return {
    type: actionTypes.CLIENT_MASTER_CREATE_SUCCESS,
    payload: data,
  };
};

// UPDATE
export const clientMasterUpdateStart = (data) => {
  return {
    type: actionTypes.CLIENT_MASTER_UPDATE_START,
    payload: data,
  };
};

export const clientMasterUpdateSuccess = (data) => {
  showSuccessNotification("Client Updated Successfully!", configToast);
  return {
    type: actionTypes.CLIENT_MASTER_UPDATE_SUCCESS,
    payload: data,
  };
};
// **************************** CLIENT MASTER END ****************************

// **************************** CLIENT AGREEMENT START ****************************
export const clientAgreementTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  return {
    type: actionTypes.CLIENT_AGREEMENT_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const clientAgreementTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Successfully!', configToast)
  return {
    type: actionTypes.CLIENT_AGREEMENT_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const clientAgreementTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.CLIENT_AGREEMENT_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// add client agreement
export const clientAgreementCreateStart = (data) => {
  return {
    type: actionTypes.CLIENT_AGREEMENT_CREATE_START,
    payload: data,
  };
};
export const clientAgreementCreateSuccess = (data) => {
  showSuccessNotification("Client Agreement Added Successfully!", configToast);
  return {
    type: actionTypes.CLIENT_AGREEMENT_CREATE_SUCCESS,
    payload: data,
  };
};

// update client agreement
export const clientAgreementUpdateStart = (data) => {
  return {
    type: actionTypes.CLIENT_AGREEMENT_UPDATE_START,
    payload: data,
  };
};
export const clientAgreementUpdateSuccess = (data) => {
  showSuccessNotification("Client Agreement Updated Successfully!", configToast);
  return {
    type: actionTypes.CLIENT_AGREEMENT_UPDATE_SUCCESS,
    payload: data,
  };
};
// **************************** CLIENT AGREEMENT END ****************************

// **************************** EXTERNAL PRODUCT MASTER START ****************************
export const externalProductMasterTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  return {
    type: actionTypes.EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const externalProductMasterTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Successfully!', configToast);
  return {
    type: actionTypes.EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const externalProductMasterTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// Create
export const externalProductMasterCreateStart = (data) => {
  return {
    type: actionTypes.EXTERNAL_PRODUCT_MASTER_CREATE_START,
    payload: data,
  };
};
export const externalProductMasterCreateSuccess = (data) => {
  showSuccessNotification("External Product Added Successfully!", configToast);
  return {
    type: actionTypes.EXTERNAL_PRODUCT_MASTER_CREATE_SUCCESS,
    payload: data,
  };
};
export const externalProductMasterCreateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.EXTERNAL_PRODUCT_MASTER_CREATE_FAILED,
    payload: error,
  };
};
// Update
export const externalProductMasterUpdateStart = (data) => {
  return {
    type: actionTypes.EXTERNAL_PRODUCT_MASTER_UPDATE_START,
    payload: data,
  };
};
export const externalProductMasterUpdateSuccess = (data) => {
  showSuccessNotification("External Product Updated Successfully!", configToast);
  return {
    type: actionTypes.EXTERNAL_PRODUCT_MASTER_UPDATE_SUCCESS,
    payload: data,
  };
};
export const externalProductMasterUpdateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.EXTERNAL_PRODUCT_MASTER_UPDATE_FAILED,
    payload: error,
  };
};
// **************************** EXTERNAL PRODUCT MASTER END ****************************

// **************************** PURCHASE ORDER START ****************************
export const purchaseOrderTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  return {
    type: actionTypes.PURCHASE_ORDER_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const purchaseOrderTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Successfully!', configToast)
  return {
    type: actionTypes.PURCHASE_ORDER_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const purchaseOrderTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.PURCHASE_ORDER_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

export const purchaseOrderTableDataPostStart = (data) => {
  return {
    type: actionTypes.PURCHASE_ORDER_TABLE_DATA_POST_START,
    payload: data,
  };
};

export const purchaseOrderTableDataPostSuccess = (data) => {
  showSuccessNotification("Purchase Order Added Successfully!", configToast);
  return {
    type: actionTypes.PURCHASE_ORDER_TABLE_DATA_POST_SUCCESS,
    payload: data,
  };
};

export const purchaseOrderTableDataPutStart = (data) => {
  return {
    type: actionTypes.PURCHASE_ORDER_TABLE_DATA_PUT_START,
    payload: data,
  };
};

export const purchaseOrderTableDataPutSuccess = (data) => {
  showSuccessNotification("Purchase Order Updated Successfully!", configToast);
  return {
    type: actionTypes.PURCHASE_ORDER_TABLE_DATA_PUT_SUCCESS,
    payload: data,
  };
};
// **************************** PURCHASE ORDER END ****************************

// **************************** ACCOUNT MASTER START ****************************
export const accountMasterTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  return {
    type: actionTypes.ACCOUNT_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const accountMasterTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Successfully!', configToast)
  return {
    type: actionTypes.ACCOUNT_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const accountMasterTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.ACCOUNT_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// Add Account master
export const accountCreateStart = (data) => {
  return {
    type: actionTypes.ACCOUNT_CREATE_START,
    payload: data,
  };
};

export const accountCreateSuccess = (data) => {
  showSuccessNotification("Account Added Successfully!", configToast);
  return {
    type: actionTypes.ACCOUNT_CREATE_SUCCESS,
    payload: data,
  };
};
export const accountCreateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.ACCOUNT_CREATE_FAILED,
    payload: error,
  };
};
// update Account Master
export const accountUpdateStart = (data) => {
  return {
    type: actionTypes.ACCOUNT_UPDATE_START,
    payload: data,
  };
};

export const accountUpdateSuccess = (data) => {
  showSuccessNotification("Account Updated Successfully!", configToast);
  return {
    type: actionTypes.ACCOUNT_UPDATE_SUCCESS,
    payload: data,
  };
};
export const accountUpdateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.ACCOUNT_UPDATE_FAILED,
    payload: error,
  };
};
// **************************** ACCOUNT MASTER END ****************************

// **************************** EXTERNAL RESOURCE START ****************************

export const externalResourceTableDataFetchStart = () => {
  return {
    type: actionTypes.EXTERNALRESOURCE_TABLE_DATA_FETCH_START,
  };
};

export const externalResourceTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Succefully', configToast)
  return {
    type: actionTypes.EXTERNALRESOURCE_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const externalResourceTableDataFetchFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.EXTERNALRESOURCE_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// ADD
export const externalResourceCreateStart = (data) => {
  return {
    type: actionTypes.EXTERNALRESOURCE_CREATE_START,
    payload: data,
  };
};
export const externalResourceCreateSuccess = (data) => {
  showSuccessNotification("External Resource Added Successfully!", configToast);
  return {
    type: actionTypes.EXTERNALRESOURCE_CREATE_SUCCESS,
    payload: data,
  };
};
// UPDATE
export const externalResourceUpdateStart = (data) => {
  return {
    type: actionTypes.EXTERNALRESOURCE_UPDATE_START,
    payload: data,
  };
};
export const externalResourceUpdateSuccess = (data) => {
  showSuccessNotification("External Resource Updated Successfully!", configToast);
  return {
    type: actionTypes.EXTERNALRESOURCE_UPDATE_SUCCESS,
    payload: data,
  };
};

// **************************** EXTERNAL RESOURCE END ****************************

// **************************** REQUEST CLIENT START ****************************
export const requestClientTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  return {
    type: actionTypes.REQUEST_CLIENT_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const requestClientTableDataFetchSuccess = (data, count) => {
  // showSuccessNotification('Data Loaded Successfully!', configToast)
  return {
    type: actionTypes.REQUEST_CLIENT_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
    count: count,
  };
};
export const requestClientTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.REQUEST_CLIENT_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

export const requestClientTableDataUpdateStart = (id) => {
  return {
    type: actionTypes.REQUEST_CLIENT_TABLE_DATA_UPDATE_START,
    payload: id,
  };
};

export const requestClientTableDataUpdateSuccess = (data) => {
  showSuccessNotification("Client Request Updated Successfully!", configToast);
  return {
    type: actionTypes.REQUEST_CLIENT_TABLE_DATA_UPDATE_SUCCESS,
    payload: data,
  };
};

export const requestClientTableDataUpdateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.REQUEST_CLIENT_TABLE_DATA_UPDATE_FAILED,
    payload: error,
  };
};
// **************************** REQUEST CLIENT END ****************************

// **************************** TECHNOLOGY MASTER START ****************************
export const TechnologyMasterTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  return {
    type: actionTypes.TECHNOLOGY_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const TechnologyMasterTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Successfully!', configToast)
  return {
    type: actionTypes.TECHNOLOGY_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const TechnologyMasterTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.TECHNOLOGY_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

export const TechnologyMasterTableDataPostStart = (data) => {
  return {
    type: actionTypes.TECHNOLOGY_DATA_POST_START,
    payload: data,
  };
};

export const TechnologyMasterTableDataPostSuccess = (data) => {
  showSuccessNotification("Technology Added Successfully!", configToast);
  return {
    type: actionTypes.TECHNOLOGY_DATA_POST_SUCCESS,
    payload: data,
  };
};

export const TechnologyMasterTableDataPostFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.TECHNOLOGY_DATA_POST_FAILED,
    payload: error,
  };
};

export const TechnologyMasterTableDataPutStart = (data) => {
  return {
    type: actionTypes.TECHNOLOGY_DATA_PUT_START,
    payload: data,
  };
};

export const TechnologyMasterTableDataPutSuccess = (data) => {
  showSuccessNotification("Technology Updated Successfully!", configToast);
  return {
    type: actionTypes.TECHNOLOGY_DATA_PUT_SUCCESS,
    payload: data,
  };
};

export const TechnologyMasterTableDataPutFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.TECHNOLOGY_DATA_PUT_FAILED,
    payload: error,
  };
};

// **************************** TECHNOLOGY MASTER END ****************************

// **************************** REASON MASTER START ****************************
export const reasonMasterTableDataFetchStart = (
  searchValue,
  from,
  to,
  head,
  order
) => {
  return {
    type: actionTypes.REASON_MASTER_TABLE_DATA_FETCH_START,
    payload: { searchValue, from, to, head, order },
  };
};

export const reasonMasterTableDataFetchSuccess = (data) => {
  // showSuccessNotification('Data Loaded Successfully!', configToast);
  return {
    type: actionTypes.REASON_MASTER_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const reasonMasterTableDataFetchFailed = (error) => {
  // showErrorNotification(error?.data?.message, configToast)
  return {
    type: actionTypes.REASON_MASTER_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// Create
export const reasonMasterCreateStart = (data) => {
  return {
    type: actionTypes.REASON_MASTER_CREATE_START,
    payload: data,
  };
};
export const reasonMasterCreateSuccess = (data) => {
  showSuccessNotification("Reason Added Successfully!", configToast);
  return {
    type: actionTypes.REASON_MASTER_CREATE_SUCCESS,
    payload: data,
  };
};
export const reasonMasterCreateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.REASON_MASTER_CREATE_FAILED,
    payload: error,
  };
};
// Update
export const reasonMasterUpdateStart = (data) => {
  return {
    type: actionTypes.REASON_MASTER_UPDATE_START,
    payload: data,
  };
};
export const reasonMasterUpdateSuccess = (data) => {
  showSuccessNotification("Reason Updated Successfully!", configToast);
  return {
    type: actionTypes.REASON_MASTER_UPDATE_SUCCESS,
    payload: data,
  };
};
export const reasonMasterUpdateFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.REASON_MASTER_UPDATE_FAILED,
    payload: error,
  };
};
// **************************** REASON MASTER END ****************************

// **************************** Setting START ****************************
export const SettingTableDataFetchStart = (data) => {
  return {
    type: actionTypes.SETTING_TABLE_DATA_FETCH_START,
    payload: data,
  };
};

export const SettingTableDataFetchSuccess = (data) => {

  // showSuccessNotification('Data Loaded Successfully!', configToast)
  return {
    type: actionTypes.SETTING_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const SettingTableDataFetchFailed = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.SETTING_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

export const SettingTableDataPutStart = (data) => {
  return {
    type: actionTypes.SETTING_TABLE_DATA_PUT_START,
    payload: data,
  };

};

export const SettingTableDataPutSuccess = (data) => {
  showSuccessNotification("Setting Updated Successfully!", configToast);
  return {
    type: actionTypes.SETTING_TABLE_DATA_PUT_SUCCESS,
    payload: data,
  };
};
// **************************** Setting End ****************************
export const FetchPermissions = (id) => {
  return {
    type: actionTypes.FETCH_PERMISSIONS,
    payload: id,
  };
};

export const FetchPermissionsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PERMISSIONS_SUCCESS,
    payload: data,
  };
};

export const ClearPermissions = () => {
  return {
    type: actionTypes.CLEAR_PERMISSIONS,
  };
};