export const HOME = "/home";
export const CURRENT_RESOURCE = "/current-resource-filter";
export const INVOICE_MASTER_INVOICE_DETAILS = "/invoice-master/invoice-details";
export const INTERNAL_INVOICE_HISTORY = "/internal-invoice-history"; 
export const GET_ALL_PURCHASE_ARCHIVE="/purchase/getAllArchive";
export const UPDATE_ALL_PURCHASE_ARCHIVE = "/purchase/archive";
export const TEAM_MEMBER_= "/member";
export const REGESTER_FORM = "/client-request";
export const ROLES = "role-permission/get-all-role";
export const ADD_ROLE = "role-permission/add-role";
export const EDIT_ROLE = `role-permission/edit-role/`;
export const PERMISSION = "role-permission"
export const INVOICE_FILTER = `invoice-master`






